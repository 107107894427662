/* eslint-disable */
import * as React from 'react';

interface IconProps {
  width?: string;
  height?: string;
  fill?: string;
  className?: string;
  viewBox?: string;
}

export const Notification = (props: IconProps) => (
  <svg
    className={`a-icon a-icon--notification ${props.className || ''}`}
    width="1em"
    height="1em"
    viewBox="0 0 16 20"
    {...props}
  >
    <title>{'icon'}</title>
    <desc>{'Created with Sketch.'}</desc>
    <g
      id="\uD83D\uDC40-fs24-Resultate---\xDCbersicht"
      stroke="none"
      strokeWidth={1}
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="fs24-mvp-l-results-v1"
        transform="translate(-1160.000000, -391.000000)"
      >
        <g id="notification" transform="translate(1124.000000, 181.000000)">
          <g id="icon" transform="translate(32.000000, 208.000000)">
            <polygon id="Path" points="0 0 24 0 24 24 0 24" />
            <path
              d="M12,22 C13.1,22 14,21.1 14,20 L10,20 C10,21.1 10.89,22 12,22 Z M18,16 L18,11 C18,7.93 16.36,5.36 13.5,4.68 L13.5,4 C13.5,3.17 12.83,2.5 12,2.5 C11.17,2.5 10.5,3.17 10.5,4 L10.5,4.68 C7.63,5.36 6,7.92 6,11 L6,16 L4.71,17.29 C4.08,17.92 4.52,19 5.41,19 L18.58,19 C19.47,19 19.92,17.92 19.29,17.29 L18,16 Z"
              id="Shape"
              fill="#1175D9"
              fillRule="nonzero"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

Notification.displayName = 'Notification';

export default Notification;
