/* eslint-disable */
import * as React from 'react';

interface IconProps {
  width?: string;
  height?: string;
  fill?: string;
  className?: string;
  viewBox?: string;
}

export const Star = (props: IconProps) => (
  <svg
    className={`a-icon a-icon--star ${props.className || ''}`}
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    {...props}
  >
    <polygon points="22 9.24 14.81 8.62 12 2 9.19 8.63 2 9.24 7.46 13.97 5.82 21 12 17.27 18.18 21 16.55 13.97" />
  </svg>
);

Star.displayName = 'Star';

export default Star;
