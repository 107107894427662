/* eslint-disable */
import * as React from 'react';

interface IconProps {
  width?: string;
  height?: string;
  fill?: string;
  className?: string;
  viewBox?: string;
}

export const Flag = (props: IconProps) => (
  <svg
    className={`a-icon a-icon--flag ${props.className || ''}`}
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    {...props}
  >
    <polygon points="14.4 6 14 4 5 4 5 21 7 21 7 14 12.6 14 13 16 20 16 20 6" />
  </svg>
);

Flag.displayName = 'Flag';

export default Flag;
