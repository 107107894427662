/* eslint-disable */
import * as React from 'react';

interface IconProps {
  width?: string;
  height?: string;
  fill?: string;
  className?: string;
  viewBox?: string;
}

export const Warning = (props: IconProps) => (
  <svg
    className={`a-icon a-icon--warning ${props.className || ''}`}
    width="1em"
    height="1em"
    viewBox="1.2 2 14 12"
    {...props}
  >
    <path d="M2.00191445,14.0016667 L14.3552478,14.0016667 C14.8685811,14.0016667 15.1885811,13.4483333 14.9352478,13.0016667 L8.75524779,2.335 C8.49524779,1.88833333 7.85524779,1.88833333 7.60191445,2.335 L1.42191445,13.0016667 C1.16858112,13.4483333 1.48858112,14.0016667 2.00191445,14.0016667 Z M8.84858112,12.0016667 L7.51524779,12.0016667 L7.51524779,10.6683333 L8.84858112,10.6683333 L8.84858112,12.0016667 Z M8.18191445,10.0016667 C7.81524779,10.0016667 7.51524779,9.70147917 7.51524779,9.33458333 L7.51524779,6.66708333 C7.51524779,6.3001875 7.81524779,6 8.18191445,6 C8.54858112,6 8.84858112,6.3001875 8.84858112,6.66708333 L8.84858112,9.33458333 C8.84858112,9.70147917 8.54858112,10.0016667 8.18191445,10.0016667 Z" />
  </svg>
);

Warning.displayName = 'Warning';

export default Warning;
