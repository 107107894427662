/* eslint-disable */
import * as React from 'react';

interface IconProps {
  width?: string;
  height?: string;
  fill?: string;
  className?: string;
  viewBox?: string;
}

export const Checkmark = (props: IconProps) => (
  <svg
    className={`a-icon a-icon--checkmark ${props.className || ''}`}
    viewBox="0 0 20 15"
    width="1em"
    height="1em"
    {...props}
  >
    <path d="M18.4447956936741,1.2698114210418292 a1.193450680199303,1.1687742378733519 0 0 0 -1.6947811530041124,0 L6.849245122281892,11.021523004386491 L2.9238478170005107,7.700057440634162 a1.1975100362544027,1.1727496604511523 0 1 0 -1.6947811530041124,1.6597389262317155 l4.775832398824762,4.09269754384563 a1.193450680199303,1.1687742378733519 0 0 0 1.6947811530041124,0 l10.745115477848827,-10.522943563437964 a1.193450680199303,1.1687742378733519 0 0 0 0,-1.6597389262317155 z" />
  </svg>
);

Checkmark.displayName = 'Checkmark';

export default Checkmark;
