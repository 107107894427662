/* eslint-disable */
import * as React from 'react';

interface IconProps {
  width?: string;
  height?: string;
  fill?: string;
  className?: string;
  viewBox?: string;
}

export const ArrowLeft = (props: IconProps) => (
  <svg
    className={`a-icon a-icon--arrow-left ${props.className || ''}`}
    width="1em"
    height="1em"
    {...props}
  >
    <path d="M11.71 18.71a1 1 0 0 0 0-1.42L7.41 13H19a1 1 0 0 0 0-2H7.41l4.3-4.29a1.004 1.004 0 0 0-1.42-1.42l-6 6a1 1 0 0 0 0 1.42l6 6a1 1 0 0 0 1.42 0z" />
  </svg>
);

ArrowLeft.displayName = 'ArrowLeft';

export default ArrowLeft;
