/* eslint-disable */
import * as React from 'react';

interface IconProps {
  width?: string;
  height?: string;
  fill?: string;
  className?: string;
  viewBox?: string;
}

export const Contacts = (props: IconProps) => (
  <svg
    className={`a-icon a-icon--contacts ${props.className || ''}`}
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    {...props}
  >
    <path d="M21,8 L21,7 L18,9 L15,7 L15,8 L18,10 L21,8 Z M22,3 L2,3 C0.9,3 0,3.9 0,5 L0,19 C0,20.1 0.9,21 2,21 L22,21 C23.1,21 23.99,20.1 23.99,19 L24,5 C24,3.9 23.1,3 22,3 Z M8,6 C9.66,6 11,7.34 11,9 C11,10.66 9.66,12 8,12 C6.34,12 5,10.66 5,9 C5,7.34 6.34,6 8,6 Z M14,18 L2,18 L2,17 C2,15 6,13.9 8,13.9 C10,13.9 14,15 14,17 L14,18 Z M22,12 L14,12 L14,6 L22,6 L22,12 Z" />
  </svg>
);

Contacts.displayName = 'Contacts';

export default Contacts;
