/* eslint-disable */
import * as React from 'react';

interface IconProps {
  width?: string;
  height?: string;
  fill?: string;
  className?: string;
  viewBox?: string;
}

export const Fuel = (props: IconProps) => (
  <svg
    className={`a-icon a-icon--fuel ${props.className || ''}`}
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    {...props}
  >
    <path d="M19.5,7.06067543 L17.8303179,5.39099331 C17.5374247,5.09810009 17.5374247,4.62322636 17.8303179,4.33033314 C18.1232111,4.03743992 18.5980848,4.03743992 18.8909781,4.33033314 L20.658745,6.09810009 C20.6793333,6.11868842 20.6984745,6.14017594 20.7161684,6.16243623 C20.8891089,6.29982973 21,6.51196486 21,6.75 L21,19.75 C21,19.9571068 20.9160534,20.1446068 20.7803301,20.2803301 C20.6446068,20.4160534 20.4571068,20.5 20.25,20.5 L17.25,20.5 C17.0428932,20.5 16.8553932,20.4160534 16.7196699,20.2803301 C16.5839466,20.1446068 16.5,19.9571068 16.5,19.75 L16.5,12.5 L15,12.5 L15,21 L4,21 L4,5 L4,5 C4,3.8954305 4.8954305,3 6,3 L6,3 L13,3 C14.1045695,3 15,3.8954305 15,5 L15,11 L17.25,11 C17.4571068,11 17.6446068,11.0839466 17.7803301,11.2196699 C17.9160534,11.3553932 18,11.5428932 18,11.75 L18,19 L19.5,19 L19.5,7.06067543 Z M6,5 L6,10 L13,10 L13,5 L6,5 Z" />
  </svg>
);

Fuel.displayName = 'Fuel';

export default Fuel;
