import * as React from 'react';

interface IconProps {
  width?: string;
  height?: string;
  fill?: string;
  className?: string;
  viewBox?: string;
}

export const Info = (props: IconProps): JSX.Element => {
  return (
    <svg
      className={`a-icon a-icon--info ${props.className || ''}`}
      viewBox="0 0 24 24"
      width="1em"
      height="1em"
      {...props}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-6h2v6zm0-8h-2V7h2v2z"
        fill="#6C6C6C"
      />
    </svg>
  );
};

Info.displayName = 'Info';

export default Info;
