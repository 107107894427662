/* eslint-disable */
import * as React from 'react';

interface IconProps {
  width?: string;
  height?: string;
  fill?: string;
  className?: string;
  viewBox?: string;
}

export const Select = (props: IconProps) => (
  <svg
    className={`a-icon a-icon--select ${props.className || ''}`}
    fill="#1175d9"
    viewBox="0 0 24 24"
    width="1em"
    height="1em"
    {...props}
  >
    <path d="M7.41 7.84L12 12.42l4.59-4.58L18 9.25l-6 6-6-6z" />
  </svg>
);

Select.displayName = 'Select';

export default Select;
