/* eslint-disable */
import * as React from 'react';

interface IconProps {
  width?: string;
  height?: string;
  fill?: string;
  className?: string;
  viewBox?: string;
}

export const CheckboxRound = (props: IconProps) => (
  <svg
    className={`a-icon a-icon--checkbox-round ${props.className || ''}`}
    width="1em"
    height="1em"
    {...props}
  >
    <path d="M12 2C6.477 2 2 6.477 2 12s4.477 10 10 10 10-4.477 10-10A10 10 0 0 0 12 2zm5.71 7.71l-7 7a1 1 0 0 1-1.42 0l-3-3a1.004 1.004 0 0 1 1.42-1.42l2.29 2.3 6.29-6.3a1.004 1.004 0 0 1 1.42 1.42z" />
  </svg>
);

CheckboxRound.displayName = 'CheckboxRound';

export default CheckboxRound;
