/* eslint-disable */
import * as React from 'react';

interface IconProps {
  width?: string;
  height?: string;
  fill?: string;
  className?: string;
  viewBox?: string;
}

export const Key = (props: IconProps) => (
  <svg
    className={`a-icon a-icon--key ${props.className || ''}`}
    viewBox="0 0 24 24"
    width="1em"
    height="1em"
    {...props}
  >
    <path d="M12.65,10 C11.83,7.67 9.61,6 7,6 C3.69,6 1,8.69 1,12 C1,15.31 3.69,18 7,18 C9.61,18 11.83,16.33 12.65,14 L17,14 L17,18 L21,18 L21,14 L23,14 L23,10 L12.65,10 Z M7,14 C5.9,14 5,13.1 5,12 C5,10.9 5.9,10 7,10 C8.1,10 9,10.9 9,12 C9,13.1 8.1,14 7,14 Z" />
  </svg>
);

Key.displayName = 'Key';

export default Key;
