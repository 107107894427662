/* eslint-disable */
import * as React from 'react';

interface IconProps {
  width?: string;
  height?: string;
  fill?: string;
  className?: string;
  viewBox?: string;
}

export const ChevronDown = (props: IconProps) => (
  <svg
    className={`a-icon a-icon--chevron-down ${props.className || ''}`}
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    {...props}
  >
    <g>
      <path fill="none" d="M24 24H0V0h24z" opacity={0.87} />
      <path d="M15.88 9.29L12 13.17 8.12 9.29a.996.996 0 1 0-1.41 1.41l4.59 4.59c.39.39 1.02.39 1.41 0l4.59-4.59a.996.996 0 0 0 0-1.41c-.39-.38-1.03-.39-1.42 0z" />
    </g>
  </svg>
);

ChevronDown.displayName = 'ChevronDown';

export default ChevronDown;
