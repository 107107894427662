export const ID_INSURANCE_TYPE = 'insuranceType';
export const ID_LIABILITY = 'isLiabilityInsurance';
export const ID_PARTIAL_COVER = 'isPartialCoverInsurance';
export const ID_FULLY_COMPREHENSIVE = 'isFullyComprehensiveInsurance';

export const ID_PARTIAL_COVER_INSURANCE_COST_SHARING =
  'partialCoverInsuranceCostSharing';
export const ID_FREE_GARAGE_CHOICE = 'freeGarageChoice';

export const ID_FULLY_COMPREHENSIVE_INSURANCE_COST_SHARING =
  'fullyComprehensiveInsuranceCostSharing';

export const ID_IS_BONUS_PROTECTION = 'isBonusProtection';

export const ID_HAS_PROTECTIVE_CLOTHING = 'hasProtectiveClothing';
export const ID_HAS_SUSPENSION_RENUNCIATION = 'hasSuspensionRenunciation';

export const ID_CARRIED_THINGS_INSURANCE_SUM = 'carriedThingsInsuranceSum';
export const ID_IS_PARKING_DAMAGE = 'isParkingDamage';
export const ID_IS_PARKING_DAMAGE_COST_SHARING_INFINITE =
  'isParkingDamageSumUnlimited';
export const ID_HAS_EXISTING_PARKING_DAMAGE = 'hasExistingParkingDamage';

export const ID_IS_ACCIDENT_INSURANCE = 'isAccidentInsurance';
export const ID_ACCIDENT_INSURED_PERSONS = 'accidentInsuredPersons';
export const ID_ACCIDENT_INSURANCE_SUM_PAYABLE_AT_DEATH =
  'accidentInsuranceSumPayableAtDeath';
export const ID_ACCIDENT_INSURANCE_SUM_PAYABLE_AT_DISABILITY =
  'accidentInsuranceSumPayableAtDisability';
export const ID_ACCIDENT_INSURANCE_DAILY_ALLOWANCE =
  'accidentInsuranceDailyAllowance';

export const ID_INSURANCE_START_DATE = 'insuranceStartDate';
export const ID_CURRENT_INSURANCE = 'currentInsuranceCompany';
export const ID_HAS_CURRENT_INSURANCE = 'hasCurrentInsuranceCompany';
export const ID_GROSSLY_NEGLIGENT = 'grosslyNegligent';

export const ID_INSURANCE_COMPANY_NONE = 15;
