/* eslint-disable */
import * as React from 'react';

interface IconProps {
  width?: string;
  height?: string;
  fill?: string;
  className?: string;
  viewBox?: string;
}

export const Share = (props: IconProps) => (
  <svg
    className={`a-icon a-icon--share ${props.className || ''}`}
    width="1em"
    height="1em"
    {...props}
  >
    <path d="M17.333 15.516c-.675 0-1.28.266-1.742.684l-6.338-3.689a2.91 2.91 0 0 0 .08-.622 2.91 2.91 0 0 0-.08-.622l6.267-3.654c.48.445 1.111.72 1.813.72A2.663 2.663 0 0 0 20 5.667 2.663 2.663 0 0 0 17.333 3a2.663 2.663 0 0 0-2.666 2.667c0 .213.035.417.08.622L8.48 9.942a2.655 2.655 0 0 0-1.813-.72A2.663 2.663 0 0 0 4 11.89a2.663 2.663 0 0 0 2.667 2.667c.702 0 1.333-.276 1.813-.72l6.329 3.697a2.508 2.508 0 0 0-.071.578 2.599 2.599 0 0 0 2.595 2.596 2.599 2.599 0 0 0 2.596-2.596 2.599 2.599 0 0 0-2.596-2.595z" />
  </svg>
);

Share.displayName = 'Share';

export default Share;
