/* eslint-disable */
import * as React from 'react';

interface IconProps {
  width?: string;
  height?: string;
  fill?: string;
  className?: string;
  viewBox?: string;
}

export const Minus = (props: IconProps) => (
  <svg
    className={`a-icon a-icon--minus ${props.className || ''}`}
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    {...props}
  >
    <polygon points="6 11 18 11 18 13 6 13" />
  </svg>
);

Minus.displayName = 'Minus';

export default Minus;
