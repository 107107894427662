import { RootState } from '../reducers';

export const getFetchInsuranceInquiry = (state: RootState) => {
  return state.fetchInsuranceInquiry;
};

export const getInsuranceInquiry = (state: RootState) => {
  return state.fetchInsuranceInquiry.insuranceInquiry;
};

export const getSaveInsuranceInquiry = (state: RootState) => {
  return state.saveInsuranceInquiry;
};

export const getSavingInsuranceInquiryFailed = (state: RootState) => {
  return state.saveInsuranceInquiry.savingInsuranceInquiryFailed;
};

export const getSavingInsuranceInquiryError = (state: RootState) => {
  return state.saveInsuranceInquiry.error;
};
