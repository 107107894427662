/* eslint-disable */
import * as React from 'react';

interface IconProps {
  width?: string;
  height?: string;
  fill?: string;
  className?: string;
  viewBox?: string;
}

export const DocumentCheck = (props: IconProps) => (
  <svg
    className={`a-icon a-icon--document-check ${props.className || ''}`}
    width="1em"
    height="1em"
    viewBox="0 0 130 130"
    {...props}
  >
    <g fill="none" fillRule="evenodd">
      <path
        fill="#6C6C6C"
        d="M80.129 0c1.978 0 3.883.72 5.354 2.014l.39.366 20.075 20.075c1.4 1.393 2.238 3.25 2.368 5.212l.017.538v17.837c0 1.495-1.212 2.708-2.708 2.708-1.389 0-2.534-1.046-2.69-2.392l-.018-.316V28.205c0-.6-.198-1.178-.559-1.647l-.235-.268L82.038 6.205c-.417-.42-.965-.687-1.553-.765l-.356-.023H8.125c-1.387 0-2.533 1.047-2.69 2.392l-.018.316v113.75c0 1.387 1.047 2.533 2.392 2.69l.316.018h48.75c1.496 0 2.708 1.213 2.708 2.709 0 1.389-1.045 2.533-2.392 2.69l-.316.018H8.125c-4.326 0-7.864-3.384-8.111-7.648L0 121.875V8.125C0 3.799 3.384.261 7.648.014L8.125 0h72.004zM46.042 92.083c1.495 0 2.708 1.213 2.708 2.709 0 1.389-1.046 2.533-2.392 2.69l-.316.018H24.375c-1.496 0-2.708-1.213-2.708-2.708 0-1.39 1.045-2.534 2.392-2.69l.316-.019h21.667zm0-16.25c1.495 0 2.708 1.213 2.708 2.709 0 1.389-1.046 2.533-2.392 2.69l-.316.018H24.375c-1.496 0-2.708-1.213-2.708-2.708 0-1.39 1.045-2.534 2.392-2.69l.316-.019h21.667zm10.833-16.25c1.496 0 2.708 1.213 2.708 2.709 0 1.389-1.045 2.533-2.392 2.69l-.316.018h-32.5c-1.496 0-2.708-1.213-2.708-2.708 0-1.39 1.045-2.534 2.392-2.69l.316-.019h32.5zm18.958-16.25c1.496 0 2.709 1.213 2.709 2.709 0 1.389-1.046 2.533-2.393 2.69l-.316.018H24.375c-1.496 0-2.708-1.213-2.708-2.708 0-1.39 1.045-2.534 2.392-2.69l.316-.019h51.458zm-13.541-16.25c1.495 0 2.708 1.213 2.708 2.709 0 1.389-1.046 2.533-2.392 2.69l-.316.018H24.375c-1.496 0-2.708-1.213-2.708-2.708 0-1.39 1.045-2.534 2.392-2.69l.316-.019h37.917z"
      />
      <path
        fill="#198019"
        d="M94.792 59.583c19.446 0 35.208 15.762 35.208 35.209C130 114.238 114.238 130 94.792 130c-19.447 0-35.209-15.762-35.209-35.208 0-19.447 15.762-35.209 35.209-35.209zm0 5.417C78.337 65 65 78.337 65 94.792c0 16.455 13.337 29.791 29.792 29.791 16.455 0 29.791-13.336 29.791-29.791S111.247 65 94.792 65zM110.9 83.178c1.104.829 1.396 2.34.728 3.508l-.187.284-15.738 20.983c-2.252 2.99-6.498 3.59-9.53 1.316-.187-.145-.342-.274-.48-.4l-8.32-8.316c-1.058-1.058-1.058-2.773 0-3.83.976-.977 2.513-1.052 3.575-.226l.255.225 8.21 8.204.048.038c.538.404 1.274.353 1.747-.084l.165-.181 15.735-20.98c.898-1.196 2.595-1.438 3.792-.54z"
      />
    </g>
  </svg>
);

DocumentCheck.displayName = 'DocumentCheck';

export default DocumentCheck;
