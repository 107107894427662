import { RootState } from '../reducers';

export const getInquiryInputs = (state: RootState) => {
  return state.inquiryInput.inputs;
};

export const getVehicle = (state: RootState) => {
  return state.inquiryInput.inputs?.vehicle;
};

export const getInquiryStep = (state: RootState) => {
  return state.inquiryInput.step;
};

export const getInquiryValidation = (state: RootState) => {
  return state.inquiryInput.validation;
};

export const getInquiryScrollToValidation = (state: RootState) => {
  return state.inquiryInput.scrollToValidation;
};

export const getUsageDetails = (state: RootState) => {
  return state.inquiryInput.inputs?.usageDetails;
};

export const getLeasing = (state: RootState) => {
  return state.inquiryInput.inputs?.usageDetails.leasing;
};

export const getLincesePlateNumber = (state: RootState) => {
  return state.inquiryInput.inputs?.vehicle.licensePlateNumber;
};
