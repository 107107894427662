/* eslint-disable */
import * as React from 'react';

interface IconProps {
  width?: string;
  height?: string;
  fill?: string;
  className?: string;
  viewBox?: string;
}

export const Plus = (props: IconProps) => (
  <svg
    className={`a-icon a-icon--plus ${props.className || ''}`}
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    {...props}
  >
    <polygon points="11 11 3 11 3 13 11 13 11 21 13 21 13 13 21 13 21 11 13 11 13 3 11 3" />
  </svg>
);

Plus.displayName = 'Plus';

export default Plus;
