/* eslint-disable */
import * as React from 'react';

interface IconProps {
  width?: string;
  height?: string;
  fill?: string;
  className?: string;
  viewBox?: string;
}

export const HomeOffice = (props: IconProps) => (
  <svg
    className={`a-icon a-icon--home-office ${props.className || ''}`}
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    {...props}
  >
    <g fill="none" fillRule="evenodd">
      <path d="M0 0H24V24H0z" />
      <path d="M0 0H24V24H0z" />
      <g fill="#333">
        <path
          d="M17 0c.55 0 1 .45 1 1v16c0 .55-.45 1-1 1h-3v-2h2v-2h-2v-2h2v-2h-2v-.65c0-.85-.36-1.67-.99-2.23l-5-4.5L8 2.61V1c0-.55.45-1 1-1zM6 3.85c.24 0 .48.08.67.26l5 4.5c.21.19.33.46.33.74V17c0 .55-.45 1-1 1H9c-.55 0-1-.45-1-1v-4H4v4c0 .55-.45 1-1 1H1c-.55 0-1-.45-1-1V9.35c0-.29.12-.56.33-.74l5-4.5c.19-.17.43-.26.67-.26zM16 6h-2v2h2V6zm-4-4h-2v2h2V2zm4 0h-2v2h2V2z"
          transform="translate(3 3)"
        />
      </g>
    </g>
  </svg>
);
