/* eslint-disable */
import * as React from 'react';

interface IconProps {
  width?: string;
  height?: string;
  fill?: string;
  className?: string;
  viewBox?: string;
}

export const Bin = (props: IconProps) => (
  <svg
    className={`a-icon a-icon--bin ${props.className || ''}`}
    width="1em"
    height="1em"
    {...props}
  >
    <path d="M10 3.333c.46 0 .833.373.833.834l-.833 10c0 .46-.373.833-.833.833H2.5a.833.833 0 0 1-.833-.833l-.834-10c0-.46.373-.834.834-.834H10zm.833-2.5a.833.833 0 1 1 0 1.667h-10a.833.833 0 1 1 0-1.667H2.5C2.933.833 3.333 0 3.75 0h4.167c.416 0 .808.833 1.25.833h1.666z" />
  </svg>
);

Bin.displayName = 'Bin';

export default Bin;
