/* eslint-disable */
import * as React from 'react';

interface IconProps {
  width?: string;
  height?: string;
  fill?: string;
  className?: string;
  viewBox?: string;
}

export const Filter = (props: IconProps) => (
  <svg
    className={`a-icon a-icon--filter ${props.className || ''}`}
    width="1em"
    height="1em"
    {...props}
  >
    <path d="M3 17v2h6v-2H3zM3 5v2h10V5H3zm10 16v-2h8v-2h-8v-2h-2v6h2zM7 9v2H3v2h4v2h2V9H7zm14 4v-2H11v2h10zm-6-4h2V7h4V5h-4V3h-2v6z" />
  </svg>
);

Filter.displayName = 'Filter';

export default Filter;
