/* eslint-disable */
import * as React from 'react';

interface IconProps {
  width?: string;
  height?: string;
  fill?: string;
  className?: string;
  viewBox?: string;
}

export const BrokenGlass = (props: IconProps) => (
  <svg
    className={`a-icon a-icon--broken-glass ${props.className || ''}`}
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    {...props}
  >
    <g fill="none" fillRule="evenodd">
      <path d="M0 0H24V24H0z" />
      <path
        fill="#333"
        d="M12.828 19.39h3.503c.457 0 .827.361.827.805 0 .445-.37.805-.827.805H7.669c-.457 0-.827-.36-.827-.805 0-.444.37-.804.827-.804h3.503v-4.706C8.527 14.297 6.5 12.078 6.5 9.4V3h5.554l.022 2.071-1.411-.238 1.118 5.619.603-4.259 1.344.115L14.473 3H17.5v6.4c0 2.678-2.027 4.897-4.672 5.285v4.706z"
      />
    </g>
  </svg>
);

BrokenGlass.displayName = 'BrokenGlass';

export default BrokenGlass;
