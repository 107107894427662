/* eslint-disable */
import * as React from 'react';

interface IconProps {
  width?: string;
  height?: string;
  fill?: string;
  className?: string;
  viewBox?: string;
}

export const SaveAlt = (props: IconProps) => (
  <svg
    className={`a-icon a-icon--save-alt ${props.className || ''}`}
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    {...props}
  >
    <path fill="none" d="M0 0h24v24H0V0z" />
    <path d="M19 13v5c0 .55-.45 1-1 1H6c-.55 0-1-.45-1-1v-5c0-.55-.45-1-1-1s-1 .45-1 1v6c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2v-6c0-.55-.45-1-1-1s-1 .45-1 1zm-6-.33l1.88-1.88c.39-.39 1.02-.39 1.41 0 .39.39.39 1.02 0 1.41l-3.59 3.59c-.39.39-1.02.39-1.41 0L7.7 12.2c-.39-.39-.39-1.02 0-1.41.39-.39 1.02-.39 1.41 0L11 12.67V4c0-.55.45-1 1-1s1 .45 1 1v8.67z" />
  </svg>
);

SaveAlt.displayName = 'SaveAlt';

export default SaveAlt;
