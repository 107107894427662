/* eslint-disable */
import * as React from 'react';

interface IconProps {
  width?: string;
  height?: string;
  fill?: string;
  className?: string;
  viewBox?: string;
}

export const Noimage = (props: IconProps) => (
  <svg
    className={`a-icon a-icon--noimage ${props.className || ''}`}
    id="Layer_1"
    x="0px"
    y="0px"
    viewBox="0 0 512.001 512.001"
    style={{
      //@ts-ignore
      enableBackground: 'new 0 0 512.001 512.001',
    }}
    xmlSpace="preserve"
    width="1em"
    height="1em"
    {...props}
  >
    <g>
      <g>
        <path d="M505.942,476.694L35.306,6.059c-8.076-8.077-21.172-8.077-29.248,0c-8.077,8.076-8.077,21.171,0,29.248l470.636,470.636 c4.038,4.039,9.332,6.058,14.625,6.058c5.293,0,10.587-2.019,14.624-6.057C514.018,497.866,514.018,484.771,505.942,476.694z" />
      </g>
    </g>
    <g>
      <g>
        <path d="M448.532,427.85H63.467c-12.187,0-22.102-9.915-22.102-22.102V170.43c0-12.188,9.916-22.102,22.102-22.102h64.177 c11.422,0,20.682-9.26,20.682-20.682s-9.26-20.682-20.682-20.682H63.467C28.472,106.963,0,135.434,0,170.43v235.318 c0,34.995,28.472,63.467,63.467,63.467h385.065c11.423,0,20.682-9.259,20.682-20.682S459.955,427.85,448.532,427.85z" />
      </g>
    </g>
    <g>
      <g>
        <path d="M448.533,106.963h-74.501l-36.644-54.968c-3.837-5.754-10.294-9.211-17.209-9.211H191.823 c-11.422,0-20.682,9.26-20.682,20.682s9.26,20.682,20.682,20.682h117.286l36.644,54.968c3.837,5.754,10.294,9.21,17.209,9.21 h85.57c12.188,0,22.103,9.915,22.103,22.102v199.806c0,11.423,9.259,20.682,20.682,20.682c11.423,0,20.682-9.259,20.683-20.682 V170.43C512.001,135.435,483.53,106.963,448.533,106.963z" />
      </g>
    </g>
    <g>
      <g>
        <path d="M337.849,309.099c-9.427-6.451-22.296-4.039-28.747,5.388c-20.205,29.528-60.668,37.114-90.196,16.908 c-29.529-20.204-37.113-60.665-16.909-90.194c4.557-6.661,10.246-12.35,16.909-16.909c9.427-6.451,11.839-19.322,5.388-28.748 c-6.451-9.427-19.323-11.839-28.748-5.388c-10.909,7.465-20.224,16.78-27.687,27.687 c-33.085,48.352-20.665,114.606,27.687,147.691c18.309,12.528,39.176,18.529,59.846,18.529c33.916,0,67.287-16.173,87.845-46.216 C349.688,328.421,347.275,315.55,337.849,309.099z" />
      </g>
    </g>
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
  </svg>
);

Noimage.displayName = 'Noimage';

export default Noimage;
