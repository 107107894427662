/* eslint-disable */
import * as React from 'react';

interface IconProps {
  width?: string;
  height?: string;
  fill?: string;
  className?: string;
  viewBox?: string;
}

export const FeedbackBubble = (props: IconProps) => (
  <svg
    className={`a-icon a-icon--feedbackbubble ${props.className || ''}`}
    viewBox="0 0 24 24"
    width="1em"
    height="1em"
    {...props}
  >
    <path d="M12 3C5.926 3 1 6.652 1 11.156c0 2.564 1.597 4.852 4.094 6.348-.37.963-.92 2.043-1.725 3.16 1.926.813 4.118-.42 5.666-1.653.943.195 1.938.3 2.966.3C18.074 19.311 23 15.66 23 11.156S18.074 3.001 12 3z" />
  </svg>
);

FeedbackBubble.displayName = 'FeedbackBubble';

export default FeedbackBubble;
