/* eslint-disable */
import * as React from 'react';

interface IconProps {
  width?: string;
  height?: string;
  fill?: string;
  className?: string;
  viewBox?: string;
}

export const Transmission = (props: IconProps) => (
  <svg
    className={`a-icon a-icon--transmission ${props.className || ''}`}
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    {...props}
  >
    <path d="M6,11 L6,4 C6,3.44771525 5.55228475,3 5,3 L5,3 L5,3 C4.44771525,3 4,3.44771525 4,4 L4,4 L4,20 C4,20.5522847 4.44771525,21 5,21 C5.55228475,21 6,20.5522847 6,20 L6,13 L11,13 L11,20 C11,20.5522847 11.4477153,21 12,21 C12.5522847,21 13,20.5522847 13,20 L13,13 L19,13 C19.2761424,13 19.5261424,12.8880712 19.7071068,12.7071068 C19.8880712,12.5261424 20,12.2761424 20,12 L20,4 C20,3.44771525 19.5522847,3 19,3 C18.4477153,3 18,3.44771525 18,4 L18,4 L18,11 L13,11 L13,4 C13,3.44771525 12.5522847,3 12,3 C11.4477153,3 11,3.44771525 11,4 L11,4 L11,11 L6,11 Z" />
    <path d="M4,2 L6,2 C6.55228475,2 7,2.44771525 7,3 L7,5 C7,5.55228475 6.55228475,6 6,6 L4,6 C3.44771525,6 3,5.55228475 3,5 L3,3 L3,3 C3,2.44771525 3.44771525,2 4,2 L4,2 Z M11,2 L13,2 C13.5522847,2 14,2.44771525 14,3 L14,5 C14,5.55228475 13.5522847,6 13,6 L11,6 C10.4477153,6 10,5.55228475 10,5 L10,3 L10,3 C10,2.44771525 10.4477153,2 11,2 Z M18,2 L20,2 C20.5522847,2 21,2.44771525 21,3 L21,5 C21,5.55228475 20.5522847,6 20,6 L18,6 C17.4477153,6 17,5.55228475 17,5 L17,3 L17,3 C17,2.44771525 17.4477153,2 18,2 Z M11,18 L13,18 C13.5522847,18 14,18.4477153 14,19 L14,21 C14,21.5522847 13.5522847,22 13,22 L11,22 C10.4477153,22 10,21.5522847 10,21 L10,19 C10,18.4477153 10.4477153,18 11,18 Z M4,18 L6,18 C6.55228475,18 7,18.4477153 7,19 L7,21 C7,21.5522847 6.55228475,22 6,22 L4,22 C3.44771525,22 3,21.5522847 3,21 L3,19 C3,18.4477153 3.44771525,18 4,18 L4,18 Z" />
  </svg>
);

Transmission.displayName = 'Transmission';

export default Transmission;
