/* eslint-disable */
import * as React from 'react';

interface IconProps {
  width?: string;
  height?: string;
  fill?: string;
  className?: string;
  viewBox?: string;
}

export const Funnel = (props: IconProps) => (
  <svg
    className={`a-icon a-icon--funnel ${props.className || ''}`}
    width="1em"
    height="1em"
    viewBox="0 0 511.999 511.999"
    {...props}
  >
    <path d="M510.078 35.509a20.68 20.68 0 0 0-18.761-11.977H20.682A20.681 20.681 0 0 0 4.89 57.569l183.364 216.828v146.324a20.684 20.684 0 0 0 11.433 18.499l94.127 47.063a20.663 20.663 0 0 0 9.249 2.183 20.685 20.685 0 0 0 20.683-20.683V274.397L507.11 57.569a20.686 20.686 0 0 0 2.968-22.06zM287.27 253.469a20.686 20.686 0 0 0-4.889 13.355V434.32l-52.763-26.381V266.825c0-4.89-1.733-9.621-4.89-13.355L65.259 64.896h381.482L287.27 253.469z" />
  </svg>
);

Funnel.displayName = 'Funnel';

export default Funnel;
