/* eslint-disable */
import * as React from 'react';

interface IconProps {
  width?: string;
  height?: string;
  fill?: string;
  className?: string;
  viewBox?: string;
}

export const Road = (props: IconProps) => (
  <svg
    className={`a-icon a-icon--road ${props.className || ''}`}
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    {...props}
  >
    <path d="M7.4,2 L15.9552503,2 L15.9552503,2 C16.9452432,2 17.786267,2.72427918 17.933123,3.70331909 L20.333123,19.7033191 C20.4969754,20.795668 19.7442802,21.8140204 18.6519312,21.9778727 C18.5537241,21.9926038 18.4545561,22 18.3552503,22 L5,22 L5,22 C3.8954305,22 3,21.1045695 3,20 C3,19.9006942 3.00739622,19.8015262 3.02212729,19.7033191 L5.42212729,3.70331909 L5.42212729,3.70331909 C5.56898328,2.72427918 6.41000715,2 7.4,2 L7.4,2 Z M10.6776252,4 L10.6776252,8 L12.6776252,8 L12.6776252,4 L10.6776252,4 Z M10.6776252,16 L10.6776252,20 L12.6776252,20 L12.6776252,16 L10.6776252,16 Z M10.6776252,10 L10.6776252,14 L12.6776252,14 L12.6776252,10 L10.6776252,10 Z" />
  </svg>
);

Road.displayName = 'Road';

export default Road;
