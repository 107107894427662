/* eslint-disable */
import * as React from 'react';

interface IconProps {
  width?: string;
  height?: string;
  fill?: string;
  className?: string;
  viewBox?: string;
}

export const Chevron = (props: IconProps) => (
  <svg
    className={`a-icon a-icon--chevron ${props.className || ''}`}
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    {...props}
  >
    <polygon points="10 6 8.59 7.41 13.17 12 8.59 16.59 10 18 16 12" />
  </svg>
);

Chevron.displayName = 'Chevron';

export default Chevron;
