/* eslint-disable */
import * as React from 'react';

interface IconProps {
  width?: string;
  height?: string;
  fill?: string;
  className?: string;
  viewBox?: string;
}

export const Message = (props: IconProps) => (
  <svg
    className={`a-icon a-icon--message ${props.className || ''}`}
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    {...props}
  >
    <path d="M20,3 L4,3 C2.9,3 2.01,3.9 2.01,5 L2,23 L6,19 L20,19 C21.1,19 22,18.1 22,17 L22,5 C22,3.9 21.1,3 20,3 Z M9,12 L7,12 L7,10 L9,10 L9,12 Z M13,12 L11,12 L11,10 L13,10 L13,12 Z M17,12 L15,12 L15,10 L17,10 L17,12 Z" />
  </svg>
);

Message.displayName = 'Message';

export default Message;
