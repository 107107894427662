/* eslint-disable */
import * as React from 'react';

interface IconProps {
  width?: string;
  height?: string;
  fill?: string;
  className?: string;
  viewBox?: string;
}

export const Download = (props: IconProps) => (
  <svg
    className={`a-icon a-icon--download ${props.className || ''}`}
    width="1em"
    height="1em"
    viewBox="2 2 16 16"
    {...props}
  >
    <path
      d="M15.8333333,10.8333333 L15.8333333,15 C15.8333333,15.4583333 15.4583333,15.8333333 15,15.8333333 L5,15.8333333 C4.54166667,15.8333333 4.16666667,15.4583333 4.16666667,15 L4.16666667,10.8333333 C4.16666667,10.375 3.79166667,10 3.33333333,10 C2.875,10 2.5,10.375 2.5,10.8333333 L2.5,15.8333333 C2.5,16.75 3.25,17.5 4.16666667,17.5 L15.8333333,17.5 C16.75,17.5 17.5,16.75 17.5,15.8333333 L17.5,10.8333333 C17.5,10.375 17.125,10 16.6666667,10 C16.2083333,10 15.8333333,10.375 15.8333333,10.8333333 Z M10.8333333,10.5583333 L12.4,8.99166667 C12.725,8.66666667 13.25,8.66666667 13.575,8.99166667 C13.9,9.31666667 13.9,9.84166667 13.575,10.1666667 L10.5833333,13.1583333 C10.2583333,13.4833333 9.73333333,13.4833333 9.40833333,13.1583333 L6.41666667,10.1666667 C6.09166667,9.84166667 6.09166667,9.31666667 6.41666667,8.99166667 C6.74166667,8.66666667 7.26666667,8.66666667 7.59166667,8.99166667 L9.16666667,10.5583333 L9.16666667,3.33333333 C9.16666667,2.875 9.54166667,2.5 10,2.5 C10.4583333,2.5 10.8333333,2.875 10.8333333,3.33333333 L10.8333333,10.5583333 Z"
      id="Shape"
    />
  </svg>
);

Download.displayName = 'Download';

export default Download;
