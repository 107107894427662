/* eslint-disable */
import * as React from 'react';

interface IconProps {
  width?: string;
  height?: string;
  fill?: string;
  className?: string;
  viewBox?: string;
}

export const Creditcard = (props: IconProps) => (
  <svg
    className={`a-icon a-icon--creditcard ${props.className || ''}`}
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    {...props}
  >
    <path d="M20,4 L4,4 C2.89,4 2.01,4.89 2.01,6 L2,18 C2,19.11 2.89,20 4,20 L20,20 C21.11,20 22,19.11 22,18 L22,6 C22,4.89 21.11,4 20,4 Z M20,18 L4,18 L4,12 L20,12 L20,18 Z M20,8 L4,8 L4,6 L20,6 L20,8 Z" />
  </svg>
);

Creditcard.displayName = 'Creditcard';

export default Creditcard;
