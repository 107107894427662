/* eslint-disable */
import * as React from 'react';

interface IconProps {
  width?: string;
  height?: string;
  fill?: string;
  className?: string;
  viewBox?: string;
}

export const Tools = (props: IconProps) => (
  <svg
    className={`a-icon a-icon--tools ${props.className || ''}`}
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <path
      d="M21.6701 18.1699L16.9501 13.4499C16.4701 12.9699 15.9601 12.8599 15.3701 12.8599L12.8301 15.3999C12.8301 15.9899 12.9401 16.5099 13.4201 16.9799L18.1401 21.6999C18.5301 22.0899 19.1601 22.0899 19.5501 21.6999L21.6701 19.5799C22.0601 19.1999 22.0601 18.5599 21.6701 18.1699Z"
      fill="#333333"
    />
    <path
      d="M16.6296 9.48995C17.0196 9.87995 17.6496 9.87995 18.0396 9.48995L18.7496 8.77994L20.8696 10.8999C22.0396 9.72995 22.0396 7.82994 20.8696 6.65995L18.0396 3.82995C17.6496 3.43995 17.0196 3.43995 16.6296 3.82995L15.9196 4.53995V1.99995C15.9196 1.37995 15.1596 1.04995 14.7096 1.49995L12.1696 4.03995C11.7196 4.48995 12.0496 5.24995 12.6696 5.24995H15.2096L14.4996 5.95995C14.1096 6.34995 14.1096 6.97995 14.4996 7.36995L14.8496 7.71994L11.9596 10.6099L7.84957 6.47995V5.47995C7.84957 5.20995 7.73957 4.95995 7.55957 4.76995L5.53957 2.73995C5.14957 2.34995 4.51957 2.34995 4.12957 2.73995L2.70957 4.15995C2.31957 4.54995 2.31957 5.17995 2.70957 5.56995L4.72957 7.59995C4.91957 7.78995 5.16957 7.88995 5.43957 7.88995H6.43957L10.5696 12.0199L9.71957 12.8699H8.41957C7.88957 12.8699 7.37957 13.0799 7.00957 13.4599L2.28957 18.1799C1.89957 18.5699 1.89957 19.1999 2.28957 19.5899L4.40957 21.7099C4.79957 22.0999 5.42957 22.0999 5.81957 21.7099L10.5396 16.9899C10.9196 16.6099 11.1296 16.1099 11.1296 15.5799V14.2899L16.2796 9.13995L16.6296 9.48995Z"
      fill="#333333"
    />
  </svg>
);

Tools.displayName = 'Tools';

export default Tools;
