/* eslint-disable */
import * as React from 'react';

interface IconProps {
  width?: string;
  height?: string;
  fill?: string;
  className?: string;
  viewBox?: string;
}

export const Motorcycle = (props: IconProps) => (
  <svg
    className={`a-icon a-icon--motorcycle ${props.className || ''}`}
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    {...props}
  >
    <path d="M12.5,23 C11.672,23 11,22.328 11,21.5 L11,16.5 C11,15.672 11.672,15 12.5,15 C13.328,15 14,15.672 14,16.5 L14,21.5 C14,22.328 13.328,23 12.5,23 L12.5,23 Z M14,6 L11,6 C8.791,6 7,7.791 7,10 L7,17 C7,18.862 8.278,20.554 10,21 L10,16.5 C10,15.122 11.121,14 12.5,14 C13.878,14 15,15.122 15,16.5 L15,21 C16.722,20.554 18,18.862 18,17 L18,10 C18,7.791 16.209,6 14,6 L14,6 Z M15.308,2 C14.834,2 14.392,1.813 14.018,1.523 C13.599,1.196 13.074,1 12.5,1 C11.927,1 11.401,1.196 10.982,1.523 C10.608,1.813 10.166,2 9.692,2 L8,2 C7.448,2 7,2.448 7,3 L7,4 C7,4.552 7.448,5 8,5 L9.692,5 C10.166,5 10.608,5.187 10.982,5.477 C11.401,5.804 11.926,6 12.5,6 C13.074,6 13.599,5.804 14.018,5.477 C14.392,5.187 14.834,5 15.308,5 L17,5 C17.552,5 18,4.552 18,4 L18,3 C18,2.448 17.552,2 17,2 L15.308,2 L15.308,2 Z M12.5,5 C11.672,5 11,4.328 11,3.5 C11,2.672 11.672,2 12.5,2 C13.328,2 14,2.672 14,3.5 C14,4.328 13.328,5 12.5,5 L12.5,5 Z M4.5,3 C4.225,3 4,3.225 4,3.5 C4,3.775 4.225,4 4.5,4 L6,4 L6,3 L4.5,3 L4.5,3 Z M20.5,3 L19,3 L19,4 L20.5,4 C20.775,4 21,3.775 21,3.5 C21,3.225 20.775,3 20.5,3 L20.5,3 Z" />
  </svg>
);

Motorcycle.displayName = 'Motorcycle';

export default Motorcycle;
