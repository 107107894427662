/* eslint-disable */
import * as React from 'react';

interface IconProps {
  width?: string;
  height?: string;
  fill?: string;
  className?: string;
  viewBox?: string;
}

export const Send = (props: IconProps) => (
  <svg
    className={`a-icon a-icon--send ${props.className || ''}`}
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    {...props}
  >
    <polygon points="2.01 21 23 12 2.01 3 2 10 17 12 2 14" />
  </svg>
);

Send.displayName = 'Send';

export default Send;
