/* eslint-disable */
import * as React from 'react';

interface IconProps {
  width?: string;
  height?: string;
  fill?: string;
  className?: string;
  viewBox?: string;
}

export const Feedback = (props: IconProps) => (
  <svg
    className={`a-icon a-icon--feedback ${props.className || ''}`}
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      id="s-0908808c3e-a"
      d="M17 17c2.757 0 5-2.243 5-5s-2.243-5-5-5c-1.943 0-3.613 1.126-4.44 2.75a5.967 5.967 0 0 1 0 4.5C13.387 15.874 15.057 17 17 17zm-6.164-3.139a.5.5 0 1 0-.692-.722c-.014.012-1.451 1.357-3.153 1.357-1.558 0-2.639-.846-3.145-1.35a.502.502 0 0 0-.709.708c.616.613 1.935 1.642 3.854 1.642 2.111 0 3.776-1.568 3.845-1.635zM4.549 10a1 1 0 1 0 .002 2 1 1 0 0 0-.002-2zm4.95 0a1 1 0 1 0 .002 2 1 1 0 0 0-.002-2zM17 6a6 6 0 0 1 0 12 5.99 5.99 0 0 1-5-2.687A5.992 5.992 0 0 1 7 18 6 6 0 0 1 7 6a5.99 5.99 0 0 1 5 2.686A5.99 5.99 0 0 1 17 6zm2.004 8.625a.495.495 0 0 1-.293-.095c-.008-.006-.701-.53-1.717-.53-.91 0-1.388.295-1.705.525a.5.5 0 0 1-.7-.108.539.539 0 0 1 .11-.729c.405-.295 1.083-.688 2.295-.688 1.314 0 2.206.619 2.303.689a.5.5 0 0 1 .112.699c-.098.135-.251.237-.405.237zM13.5 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zm6 1a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"
    />
  </svg>
);

Feedback.displayName = 'Feedback';

export default Feedback;
