/* eslint-disable */
import * as React from 'react';

interface IconProps {
  width?: string;
  height?: string;
  fill?: string;
  className?: string;
  viewBox?: string;
}

export const Speedometer = (props: IconProps) => (
  <svg
    className={`a-icon a-icon--speedometer ${props.className || ''}`}
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    {...props}
  >
    <path d="M4.03469369,14.75 C4.22862633,16.8358114 5.22330731,18.6892732 6.70835121,20 C5.82318595,20 5.19736083,20 5,20 C4,20 3.01908958,18.4176254 2.8221177,18.0487546 C2.21367993,16.9093283 2,15.4661427 2,14 C2,8.4771525 6.4771525,4 12,4 C13.4762368,4 14.8777614,4.31988054 16.1390414,4.894109 L15.3112331,6.7152872 C14.5182949,6.35428276 13.6560697,6.1189374 12.75,6.03469369 L12.75,8.5 L11.25,8.5 L11.25,6.03469369 C9.49184015,6.19816225 7.89876567,6.93056585 6.6566645,8.04601654 L8.48196831,9.87132034 L7.42130814,10.9319805 L5.63816476,9.14883713 C4.75293988,10.3079568 4.17730616,11.7161545 4.03469369,13.25 L6.5,13.25 L6.5,14.75 L4.03469369,14.75 Z M19.9653063,14.75 L17.5,14.75 L17.5,13.25 L19.9653063,13.25 C19.8810626,12.3439303 19.6457172,11.4817051 19.2847128,10.6887669 L21.105891,9.86095864 C21.6801195,11.1222386 22,12.5237632 22,14 C22,15.4105785 21.7430868,16.8350172 21.1051848,18.0560835 C20.9265277,18.3980673 20,20 19,20 C18.8026392,20 18.1768141,20 17.2916488,20 C18.7766927,18.6892732 19.7713737,16.8358114 19.9653063,14.75 Z M11.1716301,12.6203567 L18.4486054,6.67043079 L19.2289863,7.45081174 L13.2790605,14.727787 L13.2790605,14.727787 C12.7554965,15.3681247 11.8119678,15.4627885 11.1716301,14.9392246 C11.1329762,14.9076197 11.095927,14.8741016 11.0606213,14.8387959 C10.4757488,14.2539234 10.4757488,13.3056578 11.0606213,12.7207854 C11.095927,12.6854796 11.1329762,12.6519615 11.1716301,12.6203567 L11.1716301,12.6203567 Z" />
    <path d="M4,18 L20,18 L20,19 C20,19.5522847 19.5522847,20 19,20 L5,20 C4.44771525,20 4,19.5522847 4,19 L4,18 Z" />
  </svg>
);

Speedometer.displayName = 'Speedometer';

export default Speedometer;
