/* eslint-disable */
import * as React from 'react';

interface IconProps {
  width?: string;
  height?: string;
  fill?: string;
  className?: string;
  viewBox?: string;
}

export const CheckmarkIcon = (props: IconProps) => (
  <svg
    className={`a-icon a-icon--checkmark-icon ${props.className || ''}`}
    width="1em"
    height="1em"
    style={{}}
    {...props}
  >
    <rect
      id="backgroundrect"
      width="100%"
      height="100%"
      x={0}
      y={0}
      fill="none"
      stroke="none"
    />
    <title>{'atoms/icons/check'}</title>
    <desc>{'Created with Sketch.'}</desc>
    <defs>
      <path
        d="M18.71,6.29 C18.5222334,6.10068735 18.2666375,5.99420168 18,5.99420168 C17.7333625,5.99420168 17.4777666,6.10068735 17.29,6.29 L9,14.63 L5.71,11.79 C5.31787783,11.3978778 4.68212217,11.3978778 4.29,11.79 C3.89787783,12.1821222 3.89787783,12.8178778 4.29,13.21 L8.29,16.71 C8.4777666,16.8993127 8.73336246,17.0057983 9,17.0057983 C9.26663754,17.0057983 9.5222334,16.8993127 9.71,16.71 L18.71,7.71 C18.8993127,7.5222334 19.0057983,7.26663754 19.0057983,7 C19.0057983,6.73336246 18.8993127,6.4777666 18.71,6.29 Z"
        id="path-1"
      />
    </defs>
    <g className="currentLayer" style={{}}>
      <title>{'Layer 1'}</title>
      <g
        id="\uD83C\uDFAF-fs24-Landing-Page"
        stroke="none"
        strokeWidth={1}
        fill="none"
        fillRule="evenodd"
        className="selected"
      >
        <g id="fs24-mvp-l-landingpage">
          <g id="text">
            <g id="Group-9">
              <g id="Stacked-Group">
                <g id="Group-13">
                  <g id="atoms/icons/check">
                    <mask
                      id="mask-2"
                      fill="white"
                      transform="translate(0.004091739654541016,0.00579833984375) translate(0,120) translate(239,179) translate(-243,-305) translate(0,0) scale(25.091543197631836,30.78333854675293) translate(0,0) translate(0,0) scale(1.001258373260498,1.0125832557678223) translate(0,0) "
                    >
                      <use xlinkHref="#path-1" id="svg_1" />
                    </mask>
                    <use
                      id="Mask"
                      fill="#ffffff"
                      fillRule="nonzero"
                      xlinkHref="#path-1"
                      y={-5.99420166015625}
                      x={-3.995908260345459}
                      transform="matrix(25.123117724656367,0,0,31.170693169074184,0,0) "
                    />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

CheckmarkIcon.displayName = 'CheckmarkIcon';

export default CheckmarkIcon;
