import {
  useMediaQuery,
  useMinBreakpoint,
  theme,
} from '@scout24ch/fs24-design-system';

export const MOBILE = 'mobile';
export const TABLET = 'tablet';
export const DESKTOP = 'desktop';

export const BREAKPOINTS = {
  mobile: 0,
  tablet: 752,
  desktop: 1363,
};

export const useIsDesktop = (): boolean => useMinBreakpoint('lg');

export const useIsTablet = (): boolean => {
  const [isMinTabletWidth, isNotDesktopWidth] = useMediaQuery([
    `(min-width: ${theme.breakpoints.md})`,
    `(max-width: ${theme.breakpoints.lg})`,
  ]);

  return isMinTabletWidth && isNotDesktopWidth;
};

export const useIsMobile = (): boolean => {
  const [isMobile] = useMediaQuery(`(max-width: ${theme.breakpoints.md})`);

  return isMobile;
};

export const useIsMobileOrTablet = () => {
  const isMobile = useIsMobile();
  const isTablet = useIsTablet();

  return isMobile || isTablet;
};
