/* eslint-disable */
import * as React from 'react';

interface IconProps {
  width?: string;
  height?: string;
  fill?: string;
  className?: string;
  viewBox?: string;
}

export const Searchjob = (props: IconProps) => (
  <svg
    className={`a-icon a-icon--searchjob ${props.className || ''}`}
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    {...props}
  >
    <path d="M14.5,15 L13.7,15 L13.4,14.7 C14.4,13.6 15,12.1 15,10.5 C15,6.9 12.1,4 8.5,4 C4.9,4 2,6.9 2,10.5 C2,14.1 4.9,17 8.5,17 C10.1,17 11.6,16.4 12.7,15.4 L13,15.7 L13,16.5 L18,21.5 L19.5,20 L14.5,15 L14.5,15 Z M8.5,15 C6,15 4,13 4,10.5 C4,8 6,6 8.5,6 C11,6 13,8 13,10.5 C13,13 11,15 8.5,15 L8.5,15 Z" />
    <path d="M19,2 L11,2 C10.45,2 10,2.45 10,3 L10,9 C10,9.55 10.45,10 11,10 L19,10 C19.55,10 20,9.55 20,9 L20,3 C20,2.45 19.55,2 19,2 L19,2 Z M19,4 L15,6.5 L11,4 L11,3 L15,5.5 L19,3 L19,4 L19,4 Z" />
  </svg>
);

Searchjob.displayName = 'Searchjob';

export default Searchjob;
