/* eslint-disable */
import * as React from 'react';

interface IconProps {
  width?: string;
  height?: string;
  fill?: string;
  className?: string;
  viewBox?: string;
}

export const ArrowTop = (props: IconProps) => (
  <svg
    className={`a-icon a-icon--arrowtop ${props.className || ''}`}
    id="Ebene_1"
    data-name="Ebene 1"
    viewBox="0 0 32 32"
    width="1em"
    height="1em"
    {...props}
  >
    <defs>
      <style>
        {
          '.cls-1{fill:#fff;}.cls-1,.cls-3{fill-rule:evenodd;}.cls-2{mask:url(#mask);}.cls-3{fill:#0073e6;}.cls-4{fill:none;}'
        }
      </style>
      <mask
        id="mask"
        x={0}
        y={0}
        width={32}
        height={32}
        maskUnits="userSpaceOnUse"
      >
        <g transform="translate(8 11)">
          <g id="mask-2">
            <path
              id="path-1"
              className="cls-1"
              d="M7.05.72l-6,6.67A1.33,1.33,0,0,0,.71,8.68,1.33,1.33,0,0,0,3,9.28L8,3.67l5.05,5.62A1.34,1.34,0,0,0,15,7.4L9,.73A1.35,1.35,0,0,0,8,.33,1.37,1.37,0,0,0,7.05.72Z"
            />
          </g>
        </g>
      </mask>
    </defs>
    <title>{'arrow_top'}</title>
    <g id="fs24-motoscout-m-landingpage">
      <g id="to-the-top">
        <g id="Mask-3">
          <path
            d="M7.05.72l-6,6.67A1.33,1.33,0,0,0,.71,8.68,1.33,1.33,0,0,0,3,9.28L8,3.67l5.05,5.62A1.34,1.34,0,0,0,15,7.4L9,.73A1.35,1.35,0,0,0,8,.33,1.37,1.37,0,0,0,7.05.72Z"
            transform="translate(8 11)"
          />
        </g>
        <g className="cls-2">
          <polygon
            id="rectangle"
            className="cls-3"
            points="0 0 32 0 32 32 0 32 0 0"
          />
        </g>
        <g className="cls-2">
          <rect id="Rectangle-2" className="cls-4" width={32} height={32} />
        </g>
      </g>
    </g>
  </svg>
);

ArrowTop.displayName = 'ArrowTop';

export default ArrowTop;
