/* eslint-disable */
import * as React from 'react';

interface IconProps {
  width?: string;
  height?: string;
  fill?: string;
  className?: string;
  viewBox?: string;
}

export const Bag = (props: IconProps) => (
  <svg
    className={`a-icon a-icon--bag ${props.className || ''}`}
    width="1em"
    height="1em"
    viewBox="0 0 20 18"
    fill="none"
    {...props}
  >
    <path
      d="M11 13H9C8.45 13 8 12.55 8 12H1.01V16C1.01 17.1 1.91 18 3.01 18H17C18.1 18 19 17.1 19 16V12H12C12 12.55 11.55 13 11 13ZM18 4H14C14 1.79 12.21 0 10 0C7.79 0 6 1.79 6 4H2C0.9 4 0 4.9 0 6V9C0 10.11 0.89 11 2 11H8V10C8 9.45 8.45 9 9 9H11C11.55 9 12 9.45 12 10V11H18C19.1 11 20 10.1 20 9V6C20 4.9 19.1 4 18 4ZM8 4C8 2.9 8.9 2 10 2C11.1 2 12 2.9 12 4H7.99H8Z"
      fill="#333333"
    />
  </svg>
);

Bag.displayName = 'Bag';

export default Bag;
