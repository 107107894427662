/* eslint-disable */
import * as React from 'react';

interface IconProps {
  width?: string;
  height?: string;
  fill?: string;
  className?: string;
  viewBox?: string;
}

export const Whatsapp = (props: IconProps) => (
  <svg
    className={`a-icon a-icon--whatsapp ${props.className || ''}`}
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    {...props}
  >
    <path d="M20,3 C21.1,3 22,3.9 22,5 L22,17 C22,18.1 21.1,19 20,19 L6,19 L2,23 L2.01,5 C2.01,3.9 2.9,3 4,3 L20,3 Z M9.01111111,10.3277778 L10.2333333,9.10555556 C10.3888889,8.95 10.4333333,8.73333333 10.3722222,8.53888889 C10.1666667,7.91666667 10.0555556,7.25 10.0555556,6.55555556 C10.0555556,6.25 9.80555556,6 9.5,6 L7.55555556,6 C7.25,6 7,6.25 7,6.55555556 C7,11.7722222 11.2277778,16 16.4444444,16 C16.75,16 17,15.75 17,15.4444444 L17,13.5055556 C17,13.2 16.75,12.95 16.4444444,12.95 C15.7555556,12.95 15.0833333,12.8388889 14.4611111,12.6333333 C14.2666667,12.5666667 14.0444444,12.6166667 13.8944444,12.7666667 L12.6722222,13.9888889 C11.1,13.1833333 9.81111111,11.9 9.01111111,10.3277778 Z" />
  </svg>
);

Whatsapp.displayName = 'Whatsapp';

export default Whatsapp;
