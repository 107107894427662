/* eslint-disable */
import * as React from 'react';

interface IconProps {
  width?: string;
  height?: string;
  fill?: string;
  className?: string;
  viewBox?: string;
}

export const DocumentQuestionMark = (props: IconProps) => (
  <svg
    className={`a-icon a-icon--document-question-mark ${props.className || ''}`}
    width="1em"
    height="1em"
    viewBox="0 0 104 104"
    {...props}
  >
    <g fill="none" fillRule="evenodd">
      <path
        fill="#6C6C6C"
        d="M64.103 0c1.582 0 3.107.576 4.284 1.61l.312.294 16.06 16.06c1.12 1.115 1.79 2.6 1.893 4.17l.015.43v14.27c0 1.196-.97 2.166-2.167 2.166-1.111 0-2.027-.836-2.152-1.914l-.015-.253v-14.27c0-.48-.158-.942-.446-1.317l-.189-.214L65.631 4.964c-.335-.336-.773-.55-1.243-.612l-.285-.019H6.5c-1.11 0-2.027.838-2.152 1.915l-.015.252v91c0 1.11.838 2.027 1.915 2.152l.252.015h39c1.197 0 2.167.97 2.167 2.166 0 1.111-.837 2.027-1.914 2.152L45.5 104h-39c-3.46 0-6.291-2.707-6.489-6.118L0 97.5v-91C0 3.04 2.707.209 6.118.011L6.5 0h57.603zm-27.27 73.667c1.197 0 2.167.97 2.167 2.166 0 1.111-.836 2.027-1.914 2.152l-.253.015H19.5c-1.197 0-2.167-.97-2.167-2.167 0-1.11.837-2.027 1.914-2.152l.253-.014h17.333zm0-13c1.197 0 2.167.97 2.167 2.166 0 1.111-.836 2.027-1.914 2.152l-.253.015H19.5c-1.197 0-2.167-.97-2.167-2.167 0-1.11.837-2.027 1.914-2.152l.253-.014h17.333zm8.667-13c1.197 0 2.167.97 2.167 2.166 0 1.111-.837 2.027-1.914 2.152L45.5 52h-26c-1.197 0-2.167-.97-2.167-2.167 0-1.11.837-2.027 1.914-2.152l.253-.014h26zm15.167-13c1.196 0 2.166.97 2.166 2.166 0 1.111-.836 2.027-1.914 2.152l-.252.015H19.5c-1.197 0-2.167-.97-2.167-2.167 0-1.11.837-2.027 1.914-2.152l.253-.014h41.167zm-10.834-13c1.197 0 2.167.97 2.167 2.166 0 1.111-.836 2.027-1.914 2.152l-.253.015H19.5c-1.197 0-2.167-.97-2.167-2.167 0-1.11.837-2.027 1.914-2.152l.253-.014h30.333z"
      />
      <path
        fill="#00478C"
        d="M75.833 47.667C91.391 47.667 104 60.276 104 75.833 104 91.391 91.39 104 75.833 104c-15.557 0-28.166-12.61-28.166-28.167 0-15.557 12.609-28.166 28.166-28.166zm0 4.333C62.67 52 52 62.67 52 75.833c0 13.164 10.67 23.834 23.833 23.834 13.164 0 23.834-10.67 23.834-23.834S88.997 52 75.833 52zm0 36.474l.313.015c1.648.157 2.937 1.546 2.937 3.235 0 1.794-1.455 3.25-3.25 3.25-1.794 0-3.25-1.456-3.25-3.25 0-1.69 1.29-3.078 2.937-3.235l.313-.015zm0-29.25c5.686 0 10.292 4.605 10.292 10.291 0 4.897-3.415 8.992-7.994 10.035l-.131.025v2.399c0 1.11-.836 2.027-1.914 2.152l-.253.014c-1.11 0-2.027-.836-2.152-1.914l-.014-.252V77.64c0-1.11.836-2.027 1.914-2.152l.252-.014c3.293 0 5.959-2.666 5.959-5.959 0-3.292-2.666-5.958-5.959-5.958-3.292 0-5.958 2.666-5.958 5.958 0 1.197-.97 2.167-2.167 2.167-1.196 0-2.166-.97-2.166-2.167 0-5.686 4.605-10.291 10.291-10.291z"
      />
    </g>
  </svg>
);

DocumentQuestionMark.displayName = 'DocumentQuestionMark';

export default DocumentQuestionMark;
