/* eslint-disable */
import * as React from 'react';

interface IconProps {
  width?: string;
  height?: string;
  fill?: string;
  className?: string;
  viewBox?: string;
}

export const CoinSmall = (props: IconProps) => (
  <svg
    className={`a-icon a-icon--coin-small ${props.className || ''}`}
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    {...props}
  >
    <g fill="none" fillRule="evenodd">
      <path d="M0 0L24 0 24 24 0 24z" />
      <path
        fill="#333"
        d="M14.5 8.5c3.864 0 7 3.136 7 7s-3.136 7-7 7-7-3.136-7-7 3.136-7 7-7zm.063 1.4c-.518 0-.938.42-.938.931v.42c-1.204.259-2.177 1.043-2.177 2.247 0 1.435 1.19 2.149 2.926 2.569 1.561.371 1.869.917 1.869 1.498 0 .427-.308 1.113-1.68 1.113-1.022 0-1.554-.364-1.757-.889-.098-.245-.308-.42-.567-.42h-.168c-.42 0-.721.427-.56.812.35.861 1.176 1.372 2.107 1.568v.42c0 .511.42.931.931.931.518 0 .938-.42.938-.931v-.406c1.218-.231 2.177-.931 2.191-2.212 0-1.764-1.512-2.373-2.926-2.737-1.414-.364-1.869-.749-1.869-1.337 0-.672.63-1.148 1.68-1.148.882 0 1.323.343 1.484.875.07.252.28.434.539.434h.182c.413 0 .707-.406.567-.791-.266-.742-.875-1.344-1.841-1.582v-.434c0-.511-.42-.931-.931-.931zM8.5 2.5c3.18 0 5.868 2.125 6.719 5.031-.237-.02-.477-.031-.719-.031-1.803 0-3.467.597-4.805 1.603-.307-.114-.627-.208-.943-.289-1.414-.364-1.869-.749-1.869-1.337 0-.672.63-1.148 1.68-1.148.882 0 1.323.343 1.484.875.061.22.23.387.444.426l.095.008h.182c.413 0 .707-.406.567-.791-.247-.689-.79-1.257-1.64-1.526l-.201-.056v-.434c0-.475-.362-.87-.823-.925L8.563 3.9c-.481 0-.878.362-.932.823l-.006.108v.42c-1.204.259-2.177 1.043-2.177 2.247 0 1.435 1.19 2.149 2.926 2.569.071.017.14.034.206.052-.58.637-1.059 1.37-1.41 2.171-.171-.138-.29-.309-.364-.501-.086-.214-.257-.375-.472-.412l-.095-.008h-.168c-.42 0-.721.427-.56.812.237.584.693 1.006 1.252 1.28-.172.65-.263 1.334-.263 2.039 0 .242.01.482.032.719C3.625 15.368 1.5 12.68 1.5 9.5c0-3.864 3.136-7 7-7z"
      />
    </g>
  </svg>
);

CoinSmall.displayName = 'CoinSmall';

export default CoinSmall;
